// React
import React, {Component} from "react";
import {connect} from "react-redux";
// Routing
import {withRouter} from "react-router-dom";
// Translations
import {withTranslation} from "react-i18next";
// Material UI
import {Dialog, DialogContent} from "@material-ui/core";
// SVG
import {successSvg} from "assets";
// Components
import {Button} from "@components/Input";

class Success extends Component {

    render() {
        const {t, message, type, item} = this.props;
        var is_onboarding = message === "project" && type === "create";
        return (
            <Dialog onClose={this.props.onClose} className="dialog_success" open={true}>
                <DialogContent>
                    <div className={"dialog"}>
                        <span className={"title"}>{t(`frontend_success.${message}.${type}_title`)}</span>
                        <span className={"svg"}>{successSvg()}</span>
                        <span className={"description"} style={{marginBottom: is_onboarding ? "15px" : 0}}
                              dangerouslySetInnerHTML={{__html: t(`frontend_success.${message}.${type}_description`, {name: item?.name})}}/>
                        <Button id={"notify_button_success"} type={"success"}
                                text={t("card.dialog_notify.button_accept")}
                                onClick={(e) => this.props.onClose(e)}/>
                    </div>
                </DialogContent>
            </Dialog>
        );
    }
}

const mapStateToProps = state => {
    return {
        data: state
    };
};

const success = connect(
    mapStateToProps
)(Success);

export default withTranslation("common")(withRouter(success));
